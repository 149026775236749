import React, { useContext } from "react"
import { UserContext } from "services/User/context"
import { Grid } from "@material-ui/core"
import Link from "next/link"
import CookieConsent from "react-cookie-consent"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import Iubenda from "react-iubenda-policy"
import css from "./footer.styles.module.scss"
import clsx from "clsx"

const Footer = () => {
  // const { user } = useContext(UserContext)

  const iubendaImplementation = `<a href="https://www.iubenda.com/privacy-policy/23538022" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>`

  return (
    <Grid container className={clsx(css.footer, "content")}>
      <Grid item xs={12} align="center">
        {/* <Iubenda id={23538022} type="privacy" styling="white">
          Privacy e Cookie Policy
        </Iubenda> */}
        <div dangerouslySetInnerHTML={{ __html: iubendaImplementation }} />
      </Grid>

      <Grid item xs={12} align="center">
        <div>Copyright Badtraveller.com 2020 - 2021</div>
      </Grid>
      <Grid item xs={12} align="center">
        <div>Beta version</div>
      </Grid>
      <Grid item xs={12} align="center">
        <Link href="https://www.facebook.com/realbadtraveller">
          <FacebookIcon style={{ cursor: "pointer" }} />
        </Link>
        <Link href="https://www.instagram.com/realbadtraveller">
          <InstagramIcon style={{ cursor: "pointer" }} />
        </Link>
      </Grid>
      {/* <Grid item xs={12}>
        <pre style={{ fontSize: "9px" }}>{JSON.stringify(user, null, 2)}</pre>
      </Grid> */}
      <CookieConsent
        location="bottom"
        overlay
        buttonText="Accetta"
        style={{
          borderRadius: "20px",
          width: "80%",
          margin: "0 auto",
          textAlign: "center",
          backgroundColor: "white",
          color: "#333333",
          boxShadow: "0px 0px 11px 2px #dedede",
          left: "0",
          right: "0",
          marginBottom: 10,
        }}
        buttonStyle={{ backgroundColor: "rgb(43 110 151)", borderRadius: "8px", color: "white", padding: "8px 14px" }}
      >
        Noi e alcuni partner selezionati utilizziamo cookie o tecnologie simili come specificato nella cookie policy.
        <br />
        Puoi acconsentire all’utilizzo di tali tecnologie chiudendo questa informativa.
      </CookieConsent>
    </Grid>
  )
}

export default Footer
